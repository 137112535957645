interface Config {
  env: 'development' | 'production' | 'local' | any,
  baseUrl: string,
  vnpayRedirectDomain: string,
  googleKey: string,
  grapUrl: string,
  termOfUseUrl: { vi: string; en: string; kr: string };
}

const config: Config = {
  env: 'staging',
  baseUrl: 'https://staging.api.swift247.vn', // no ending slash!
  vnpayRedirectDomain: 'sandbox.vnpayment.vn',
  googleKey: 'AIzaSyBm1WUpSuYnJc9n-3ghI-Zo5YX47-2zuKs',
  grapUrl: 'https://api-us-east-1.graphcms.com',
  termOfUseUrl: {
    vi: 'https://swift247.vn/dieu-khoan-su-dung',
    en: 'https://kr.swift247.vn/en/term-of-use.html',
    kr: 'https://kr.swift247.vn/en/term-of-use.html',
  }
}

export default config;
